<template>
    <div class="login_wrapper">
        <div class="login_wrapper_bg">
            <div class="login_container">
                <div class="login_header" slot="login_header">
                    <h3>Premios Bonobo</h3>
                </div>
                <div class="login_form">
                    <form @submit.prevent="login">
                        <div class="form_block">
                            <input size="large" label-placeholder="Usuario" v-model="username" type="text" placeholder="Usuario"/>
                            <input size="large" label-placeholder="Password" v-model="password" type="password" placeholder="Contraseña"/>
                        </div>
                        <div class="form_block">
                            <button button="submit" size="large" type="gradient" color="primary">Login</button>
                        </div>
                        <div class="form_block">
                            <div class="info_message mal" v-if="error">
                                <p>{{error}}</p>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="login_footer">
                    <p class="legal">© Factoría Audiovisual 2021</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
    body {margin-top: 0!important;}
    * {border: 0;margin: 0;padding: 0;}
    .login_wrapper {background-color: #def7ff;font-family: 'Poppins', sans-serif;width: 100vw;height: 100vh;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;    
    }
    .login_wrapper_bg {background: url('../assets/images/login_bg.svg') center center;background-size: 250px;width: 100vw;height: 100vh;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;   
    }
    .login_container {width: 320px;}
    .login_container .form_block {margin-bottom: 20px;}
    .login_container .form_block:last-child {margin-bottom: 0;}
    .login_header {margin-bottom: 30px;}
    .login_header h3 {color: #1e8d9f;font-size: 24px;font-weight: 700;text-align: center;}
    .login_form input[type="text"], .login_form input[type="password"] {border: solid 2px #9a9a9a;font-size: 18px;padding: 15px 20px;position: relative;width: 100%;z-index: 1;    
        -moz-transition: all .3s cubic-bezier(.165,.84,.44,1);
        -webkit-transition: all .3s cubic-bezier(.165,.84,.44,1);
        -o-transition: all .3s cubic-bezier(.165,.84,.44,1);
        transition: all .3s cubic-bezier(.165,.84,.44,1);
    }
    .login_form input:hover {border-color: #000;z-index: 2;}
    .login_form input:focus {border-color: #1e8d9f;z-index: 2;}
    .login_form input.arror {background-color: #ffe9e9;border-color: #d40000;z-index: 2;}
    .login_form input:first-child {
        -webkit-border-top-left-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-topright: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px; 
    }
    .login_form input:last-child {margin-top: -2px;
        -webkit-border-bottom-right-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-bottomright: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;     
    }
    .login_form button {background-color: #1e8d9f;color: #fff;font-size: 18px;padding: 15px 20px;text-align: center;width: 100%;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        -moz-transition: all .3s cubic-bezier(.165,.84,.44,1);
        -webkit-transition: all .3s cubic-bezier(.165,.84,.44,1);
        -o-transition: all .3s cubic-bezier(.165,.84,.44,1);
        transition: all .3s cubic-bezier(.165,.84,.44,1);
    }
    .login_form button:hover {background-color: #50b9cc;}
    .login_form input:hover {z-index: 2;}
    .info_message {border-width: 2px;border-style: solid;font-weight: 700;font-size: 16px;margin-bottom: 20px;padding: 15px 20px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    }
    .info_message.mal {background-color: #ffe9e9;border-color: #d40000;color: #d40000;}
    .info_message.mal p {background-color: #ffe9e9;border-color: #d40000;color: #d40000;}
    .login_footer p.legal {color: #333;font-size: 11px;text-align: center;}
    ::placeholder {color: #c1c1c1;}
    ::-o-input-placeholder {color: #c1c1c1;}
    ::-ms-input-placeholder {color: #c1c1c1;}
    ::-moz-placeholder {color: #c1c1c1;}
    ::-webkit-input-placeholder {color: #c1c1c1;}
</style>

<script>
import axios from 'axios'

export default {
    name: "Login",
    data() {
        return {
            username: "",
            password: "",
            error: null
        };
    },
    methods: {
        login: function(){
            if(!this.username || !this.password){
                this.error = 'Rellena los campos por favor';
                return;
            }
            axios
                .post(process.env.VUE_APP_URL+'adminlogin', {
                        username: this.username,
                        password: this.password
                })
                .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            localStorage.setItem('adminuser', rs.data);
                            if(window.oldAdminUrl){
                                var temp = window.oldAdminUrl;
                                delete window.oldAdminUrl;
                                //this.$router.push(window.oldAdminUrl);
                                window.location.href = temp;
                            }
                            else{
                                //this.$router.push('/');
                                window.location.href = '/';
                            }
                        }
                        else{
                            this.error = rs.msg;
                        }
                })
                .catch(error => {
                    console.log(error);
                    this.error = 'Algo ha fallado, prueba mas tarde';
                });
        }
    },
    created: function(){
        if(localStorage.getItem('adminuser')){
            if(window.oldAdminUrl){
                delete window.oldAdminUrl;
                this.$router.push(window.oldAdminUrl);
            }
            else{
                this.$router.push('/');
            }
            //window.location.reload();
        }
    }
};
</script>